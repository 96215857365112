import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  Board,
  FailureCodesResponse,
  KeyValueProperty,
  NewBoard,
  StatusesResponse,
} from "../../models";
import { DropdownField, TextField } from "../fields";
import { HTTP_CLIENT } from "../../hooks";
import { toMac } from "../../utils";

interface Props {
  jwt: string;
  show: boolean;
  onClose: () => void;
  initialBoard?: Board;
  onSave: (newBoard: NewBoard) => Promise<void> | void;
}

export const AddEditBoardModal = ({
  jwt,
  show,
  onClose,
  initialBoard,
  onSave,
}: Props) => {
  const [newBoard, setNewBoard] = useState<NewBoard | undefined>();
  const [statuses, setStatuses] = useState<KeyValueProperty[]>([]);
  const [failureCodes, setFailureCodes] = useState<KeyValueProperty[]>([]);

  const updateStatusList = useCallback(async () => {
    try {
      const response = await HTTP_CLIENT.get<StatusesResponse>({
        path: `/api/v1/statuses`,
        headers: { Authorization: `Bearer ${jwt}` },
      });
      setStatuses(response._embedded.statuses);
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
    }
  }, [jwt]);

  const updateFailureCodeList = useCallback(async () => {
    try {
      const response = await HTTP_CLIENT.get<FailureCodesResponse>({
        path: `/api/v1/failureCodes`,
        headers: { Authorization: `Bearer ${jwt}` },
      });
      setFailureCodes(response._embedded.failureCodes);
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
    }
  }, [jwt]);

  useEffect(() => {
    if (jwt) {
      updateStatusList().catch((e) => {
        console.error(e);
        toast.error("Failed to retrieve status list");
      });
      updateFailureCodeList().catch((e) => {
        console.error(e);
        toast.error("Failed to retrieve failure code list");
      });
    }
  }, [jwt, updateStatusList, updateFailureCodeList]); // useEffect will be called whenever jwt changes

  const close = () => {
    setNewBoard(undefined);
    onClose();
  };

  const getMac = (): string => {
    return toMac(newBoard?.mac || initialBoard?.mac || "");
  };

  const getSerial = (): string => {
    return (newBoard?.serial || initialBoard?.serial || "").toUpperCase();
  };

  const getProbe = (): string => {
    return (newBoard?.probe || initialBoard?.probe || "").toUpperCase();
  };

  const getStatus = (): string | undefined => {
    return (
      newBoard?.status ||
      (initialBoard ? `/api/v1/statuses/${initialBoard.status.id}` : undefined)
    );
  };

  const getFailureCode = (): string | undefined => {
    return (
      newBoard?.failure_code ||
      (initialBoard?.failure_code
        ? `/api/v1/failureCodes/${initialBoard.failure_code.id}`
        : undefined)
    );
  };

  const getComments = (): string => {
    return newBoard?.comments || initialBoard?.comments || "";
  };

  const getSelfTestReport = (): string => {
    return newBoard?.self_test_report || initialBoard?.self_test_report || "";
  };

  const getEndOfLineTestReport = (): string => {
    return (
      newBoard?.end_of_line_test_report ||
      initialBoard?.end_of_line_test_report ||
      ""
    );
  };

  return (
    <Modal show={show} onHide={() => close()}>
      <Modal.Header closeButton>
        {initialBoard ? "Edit" : "Create"} Board
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Row>
            <TextField
              title={"MAC Address"}
              getValue={() => getMac()}
              onChange={(value) => setNewBoard({ ...newBoard, mac: value })}
            />

            <TextField
              title={"Serial Number"}
              getValue={() => getSerial()}
              onChange={(value) => setNewBoard({ ...newBoard, serial: value })}
            />

            <TextField
              title={"Debug Probe"}
              getValue={() => getProbe()}
              onChange={(value) => setNewBoard({ ...newBoard, probe: value })}
            />

            <DropdownField
              title="Status"
              required={true}
              placeholder="Select current status"
              values={statuses.map((s) => [s.name, s.name])}
              getValue={() => {
                const statusUrl = getStatus();
                if (statusUrl) {
                  const statusId = (getStatus() || "").split("/").reverse()[0];
                  return statuses.find((s) => `${s.id}` === statusId)!.name;
                } else {
                  return "";
                }
              }}
              onChange={(status) => {
                const newStatus = statuses.find(
                  (candidate) => candidate.name === status
                );
                if (newStatus)
                  setNewBoard({
                    ...newBoard,
                    status: `/api/v1/statuses/${newStatus.id}`,
                  });
                else
                  setNewBoard({
                    ...newBoard,
                    status: undefined,
                  });
              }}
            />

            <DropdownField
              title="Failure Code"
              required={true}
              placeholder="Select failure code"
              values={failureCodes.map((s) => [s.name, s.name])}
              getValue={() => {
                const failureCodeUrl = getFailureCode();
                if (failureCodeUrl) {
                  const failureCodeId = (getFailureCode() || "")
                    .split("/")
                    .reverse()[0];
                  return failureCodes.find((c) => `${c.id}` === failureCodeId)!
                    .name;
                } else {
                  return "";
                }
              }}
              onChange={(value) => {
                const newFailureCode = failureCodes.find(
                  (candidate) => candidate.name === value
                );
                if (newFailureCode)
                  setNewBoard({
                    ...newBoard,
                    failure_code: `/api/v1/failureCodes/${newFailureCode.id}`,
                  });
                else
                  setNewBoard({
                    ...newBoard,
                    failure_code: undefined,
                  });
              }}
            />

            <TextField
              title={"Comments"}
              getValue={() => getComments()}
              onChange={(value) =>
                setNewBoard({ ...newBoard, comments: value })
              }
            />

            <TextField
              title={"Self-Test Report"}
              getValue={() => getSelfTestReport()}
              onChange={(value) =>
                setNewBoard({ ...newBoard, self_test_report: value })
              }
            />

            <TextField
              title={"End-of-Line Test Report"}
              getValue={() => getEndOfLineTestReport()}
              onChange={(value) =>
                setNewBoard({ ...newBoard, end_of_line_test_report: value })
              }
            />
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => close()}>
          Cancel
        </Button>
        <Button
          variant="primary"
          type={"submit"}
          disabled={
            !getMac() ||
            !getSerial() ||
            !getProbe() ||
            !getStatus() ||
            !getSelfTestReport()
          }
          onClick={async () => {
            await onSave({
              mac: getMac(),
              serial: getSerial(),
              probe: getProbe(),
              status: getStatus()!,
              failure_code: getFailureCode(),
              comments: getComments(),
              self_test_report: getSelfTestReport(),
              end_of_line_test_report: getEndOfLineTestReport() || undefined,
              application_versions: [],
              lifecycle_events: [],
              board_properties: [],
            });
            close();
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
