import { Pagination } from "react-bootstrap";
import { useMemo } from "react";

export const DEFAULT_EXTRA_BUTTONS_PER_SIDE = 4;

const range = (start: number, end: number) => {
  return [...Array(end - start)].map((_, index) => index + start);
};

interface Props {
  currentPage: number;
  totalPages: number;
  extraButtonsPerSide?: number;
  onClick?: (page: number) => void;
}

export const PageNumbers = ({
  currentPage,
  totalPages,
  extraButtonsPerSide = DEFAULT_EXTRA_BUTTONS_PER_SIDE,
  onClick,
}: Props) => {
  const [min, max] = useMemo(
    (): [number, number] => [
      Math.max(0, currentPage - extraButtonsPerSide),
      Math.min(currentPage + extraButtonsPerSide, totalPages),
    ],
    [currentPage, totalPages, extraButtonsPerSide]
  );

  return (
    <Pagination>
      <Pagination.First
        key="first"
        disabled={currentPage === 0}
        onClick={() => onClick && onClick(0)}
      />
      <Pagination.Prev
        key="previous"
        disabled={currentPage === 0}
        onClick={() => onClick && onClick(currentPage - 1)}
      />
      {min !== max &&
        range(min, max).map((i) => (
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => onClick && onClick(i)}
          >
            {i + 1}
          </Pagination.Item>
        ))}

      <Pagination.Next
        key="next"
        disabled={currentPage === totalPages - 1}
        onClick={() => onClick && onClick(currentPage + 1)}
      />
      <Pagination.Last
        key="last"
        disabled={currentPage === totalPages - 1}
        onClick={() => onClick && onClick(totalPages - 1)}
      />
    </Pagination>
  );
};
