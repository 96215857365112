import "react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { Container } from "react-bootstrap";
import {
  Boards,
  KeyValueProperties,
  OAuth2RedirectHandler,
  TopMenu,
} from "./components";
import { jwtDecode } from "jwt-decode";
import {
  CustomJwtPayload,
  FailureCodesResponse,
  LifecycleEventsResponse,
  ProductPropertiesResponse,
  StatusesResponse,
} from "./models";
import { ToastContainer } from "react-toastify";

const STATUSES_PATH_PREFIX = "/api/v1/statuses";
const FAILURE_CODES_PATH_PREFIX = "/api/v1/failureCodes";
const LIFECYCLE_EVENTS_PATH_PREFIX = "/api/v1/lifecycleEvents";
const PRODUCT_PROPERTIES_PATH_PREFIX = "/api/v1/productProperties";

function getInitialJwt(): string | null {
  const initialJwt = window.localStorage.getItem("jwt");
  if (initialJwt) {
    const decodedJwt = jwtDecode<CustomJwtPayload>(initialJwt);
    const expirationTime = decodedJwt.exp || 0;
    const nowInSeconds = new Date().getTime() / 1000;
    if (expirationTime > nowInSeconds) {
      return initialJwt;
    } else {
      console.log("JWT expired. Removing from local storage.");
      window.localStorage.removeItem("jwt");
    }
  }
  return null;
}

export function App() {
  const [jwt, setJwt] = useState(getInitialJwt());
  const [authorities, setAuthorities] = useState<string[]>(
    jwt ? jwtDecode<CustomJwtPayload>(jwt).authorities : [],
  );

  const routes = [
    {
      path: "/",
      element: <Navigate to={"/boards"} />,
    },
    {
      path: "/boards",
      element: <Boards jwt={jwt} />,
    },
    {
      path: "/statuses",
      element: (
        <KeyValueProperties
          jwt={jwt}
          pathPrefix={STATUSES_PATH_PREFIX}
          singularTitle="Status"
          pluralTitle="Statuses"
          responseExtractor={(response: StatusesResponse) =>
            response._embedded.statuses
          }
        />
      ),
    },
    {
      path: "/failureCodes",
      element: (
        <KeyValueProperties
          jwt={jwt}
          pathPrefix={FAILURE_CODES_PATH_PREFIX}
          singularTitle="Failiure Code"
          pluralTitle="Failure Codes"
          responseExtractor={(response: FailureCodesResponse) =>
            response._embedded.failureCodes
          }
        />
      ),
    },
    {
      path: "/lifecycleEvents",
      element: (
        <KeyValueProperties
          jwt={jwt}
          pathPrefix={LIFECYCLE_EVENTS_PATH_PREFIX}
          singularTitle="Lifecycle Event"
          pluralTitle="Lifecycle Events"
          responseExtractor={(response: LifecycleEventsResponse) =>
            response._embedded.lifecycleEvents
          }
        />
      ),
    },
    {
      path: "/productProperties",
      element: (
        <KeyValueProperties
          jwt={jwt}
          pathPrefix={PRODUCT_PROPERTIES_PATH_PREFIX}
          singularTitle="Product Property"
          pluralTitle="Product Properties"
          responseExtractor={(response: ProductPropertiesResponse) =>
            response._embedded.productProperties
          }
        />
      ),
    },
    {
      path: "/oauth2/redirect",
      element: (
        <OAuth2RedirectHandler
          setJwt={(jwt) => {
            window.localStorage.setItem("jwt", jwt);
            setJwt(jwt);
            setAuthorities(jwtDecode<CustomJwtPayload>(jwt).authorities);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <TopMenu
        authenticated={!!jwt}
        authorities={authorities}
        logout={() => {
          setJwt(null);
          setAuthorities([]);
          window.localStorage.removeItem("jwt");
        }}
      />

      <Container fluid>
        <RouterProvider router={createBrowserRouter(routes)} />
      </Container>

      <ToastContainer autoClose={10000} theme="dark" />
    </>
  );
}
