import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

interface CopierProps {
  /** Unique ID prefix for elements inside this component */
  id: string;
  text?: string;
}

export class Copier extends Component<CopierProps> {
  render() {
    const id = `copier_${this.props.id}${this.props.text}`.replaceAll(
      /[\s:-]+/g,
      "_"
    );
    return (
      this.props.text && (
        <>
          <Button
            id={id}
            style={{ marginLeft: "1em" }}
            variant={"secondary"}
            size={"sm"}
          >
            <CopyToClipboard text={this.props.text}>
              <FontAwesomeIcon icon={faCopy} />
            </CopyToClipboard>
          </Button>

          <Tooltip
            anchorSelect={`#${id}`}
            openOnClick={true}
            closeEvents={{ mouseleave: true }}
          >
            Copied!
          </Tooltip>
        </>
      )
    );
  }
}
