import { Button, Container, Image, Nav, Navbar } from "react-bootstrap";
import { PropsWithChildren } from "react";
import logo from "./Tim-Enchanter-Monty-Python-Holy-Grail-3423336507.jpg";
import { LoginButton } from "../LoginButton";

interface Props {
  authenticated: boolean;
  authorities: string[];
  logout: () => void;
}

function NavLink({ link, children }: PropsWithChildren<{ link: string }>) {
  return (
    <Nav.Link
      href={link}
      className={window.location.pathname === link ? "active" : undefined}
    >
      {children}
    </Nav.Link>
  );
}

export function TopMenu({ authenticated, logout }: Props) {
  return (
    <Navbar bg="dark" variant="dark" expand="md">
      <Container fluid>
        <Navbar.Brand href="#">
          <Image
            src={logo}
            height={60}
            alt="TIMS Logo"
            style={{ paddingRight: "1em" }}
          />
          Token Inventory Management System
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />

        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <NavLink link="/boards">Boards</NavLink>
            <NavLink link="/statuses">Statuses</NavLink>
            <NavLink link="/failureCodes">Failure Codes</NavLink>
            <NavLink link="/lifecycleEvents">Lifecycle Events</NavLink>
            <NavLink link="/productProperties">Product Properties</NavLink>
          </Nav>

          <Navbar.Text>
            {authenticated ? (
              <Button variant="outline-primary" onClick={logout}>
                Logout
              </Button>
            ) : (
              <LoginButton />
            )}
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
