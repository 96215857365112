import { titleCase } from "title-case";

export function toHumanReadableRole(role: string): string {
  return titleCase(role.toLocaleLowerCase().replaceAll("_", " "));
}

export function fromHumanReadableRole(role: string): string {
  return role.toUpperCase().replaceAll(" ", "_");
}

export function toBetterTimeString(timestamp: string | number): string {
  const date = new Date(timestamp);
  return `${date.toLocaleTimeString()} ${date.toLocaleDateString()}`;
}

export function toMac(input: string): string {
  const upper = input.toUpperCase();
  const noColons = upper.replaceAll(":", "");
  const byteParts = noColons.match(/(..?)/g) || [];
  return byteParts.join(":");
}
