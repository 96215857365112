import React, { Component } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { TextField } from "../fields";
import { KeyValueProperty } from "../../models";

export interface NewKeyValueProperty extends Omit<KeyValueProperty, "id"> {}

interface Props {
  title: string;
  show: boolean;
  close: () => void;
  initialProperty?: KeyValueProperty;
  onSave: (newProperty: NewKeyValueProperty) => Promise<void> | void;
}

interface State {
  value?: Partial<NewKeyValueProperty>;
}

export class AddEditKeyValuePropertyModal extends Component<Props, State> {
  constructor(props: Readonly<Props> | Props) {
    super(props);
    this.state = {};
  }

  async componentDidMount(): Promise<void> {}

  render() {
    return (
      <Modal show={this.props.show} onHide={() => this.close()}>
        <Modal.Header closeButton>
          {this.props.initialProperty ? "Edit" : "Create"} {this.props.title}
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <TextField
                title={`${this.props.title} Name`}
                getValue={() => this.getName()}
                onChange={(v) =>
                  this.setState({
                    value: { ...this.state.value, name: v },
                  })
                }
              />

              <TextField
                title={`${this.props.title} Description`}
                getValue={() => this.getDescription() || ""}
                onChange={(v) =>
                  this.setState({
                    value: {
                      ...this.state.value,
                      description: v,
                    },
                  })
                }
              />
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.close()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type={"submit"}
            disabled={!this.getName()}
            onClick={async () => {
              await this.props.onSave({
                name: this.getName(),
                description: this.getDescription(),
              });
              this.close();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  private close() {
    this.setState({ value: undefined });
    this.props.close();
  }

  private getName(): string {
    return this.state.value?.name || this.props.initialProperty?.name || "";
  }

  private getDescription(): string | undefined {
    return (
      this.state.value?.description || this.props.initialProperty?.description
    );
  }
}
