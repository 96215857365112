import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HTTP_CLIENT } from "../../hooks";
import { LoginButton } from "../LoginButton";
import { KeyValueProperty } from "../../models";
import {
  AddEditKeyValuePropertyModal,
  NewKeyValueProperty,
} from "../AddEditFailureCodeModal";

interface Props<T> {
  jwt: string | null;
  pathPrefix: string;
  singularTitle: string;
  pluralTitle: string;
  responseExtractor: (response: T) => KeyValueProperty[];
}

export const KeyValueProperties = <T,>({
  jwt,
  pathPrefix,
  singularTitle,
  pluralTitle,
  responseExtractor,
}: Props<T>) => {
  const [values, setValues] = useState<KeyValueProperty[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [propertyToEdit, setPropertyToEdit] = useState<
    KeyValueProperty | undefined
  >();

  const updateList = useCallback(async () => {
    try {
      const response = await HTTP_CLIENT.get<T>({
        path: pathPrefix,
        headers: { Authorization: `Bearer ${jwt}` },
      });
      setValues(responseExtractor(response));
    } catch (e: any) {
      console.error(e.stack);
      toast.error(
        `Failed to retrieve data from ${pathPrefix} due to:\n${e.message}`
      );
    }
  }, [jwt, pathPrefix, responseExtractor]);

  const save = useCallback(
    async (newProperty: NewKeyValueProperty) => {
      const pathSuffix = propertyToEdit ? `/${propertyToEdit.id}` : "";

      try {
        await HTTP_CLIENT.request(propertyToEdit ? "PATCH" : "POST", {
          path: `${pathPrefix}${pathSuffix}`,
          headers: { Authorization: `Bearer ${jwt}` },
          body: newProperty,
        });
        await updateList();
      } catch (e: any) {
        console.error(e.stack);
        toast.error(e.message);
      }
      setShowModal(false);
    },
    [propertyToEdit, jwt, pathPrefix, updateList]
  );

  useEffect(() => {
    if (jwt) {
      updateList().catch((e) => toast.error(e));
    }
  }, [updateList, jwt]);

  if (jwt)
    return (
      <>
        <Row>
          <Col>
            <h1>
              {pluralTitle}
              {jwt && (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    setPropertyToEdit(undefined);
                    setShowModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              )}
            </h1>
          </Col>
        </Row>

        <Row>
          <Table striped hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {values
                .sort((lhs, rhs) => lhs.id - rhs.id)
                .map((property) => (
                  <tr key={`failure-code-table-${property.id}`}>
                    <td>{property.name}</td>
                    <td>{property.description}</td>
                    <td>
                      <Button
                        size="sm"
                        onClick={() => {
                          setPropertyToEdit(property);
                          setShowModal(true);
                        }}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Row>

        <AddEditKeyValuePropertyModal
          title={singularTitle}
          show={showModal}
          close={() => setShowModal(false)}
          initialProperty={propertyToEdit}
          onSave={save}
        />
      </>
    );
  else return <LoginButton />;
};
