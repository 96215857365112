import React from "react";
import { Col, Form } from "react-bootstrap";

interface DropdownFieldProps {
  title: string;
  placeholder?: string;
  required: boolean;
  values: [string, string][];
  getValue: () => string;
  onChange: ((value: string) => void) | ((value: string) => Promise<void>);
}

export const DropdownField = ({
  title,
  placeholder,
  required,
  values,
  getValue,
  onChange,
}: DropdownFieldProps) => {
  const options: [string, string | undefined][] = [];
  if (placeholder) {
    options.push([placeholder, undefined]);
  }
  options.push(...values);

  return (
    <Col xs={12} md={6}>
      <Form.Group controlId={title}>
        <Form.Label>{title}</Form.Label>

        <Form.Select
          value={getValue()}
          required={required}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        >
          {options.map(([key, value]) =>
            value ? (
              <option key={key} value={`${value}`}>
                {key}
              </option>
            ) : (
              <option key={key}>{key}</option>
            )
          )}
        </Form.Select>
      </Form.Group>
    </Col>
  );
};
