import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { HTTP_CLIENT } from "../../hooks";
import win7Logo from "./Win7Logo.png";

export class LoginButton extends Component {
  render() {
    return (
      <Button
        onClick={async () => {
          const needPort =
            (window.location.protocol === "http:" &&
              window.location.port !== "80") ||
            (window.location.protocol === "https:" &&
              window.location.port !== "443");
          const thisHost =
            `${window.location.protocol}//${window.location.hostname}` +
            (needPort ? `:${window.location.port}` : "");

          try {
            const response = await HTTP_CLIENT.get2<void>({
              path: `/api/v1/oauth2/authorize/azure-ad?redirect_uri=${thisHost}/oauth2/redirect?path=${window.location.pathname}`,
            });
            console.log(
              `Got a response! It says to redirect to ${response.headers.get(
                "Location",
              )}`,
            );

            window.location.href = response.headers.get("Location")!;
          } catch (e: any) {
            toast.error(
              <>
                <h2>Failed to login</h2>
                <p>{e.message}</p>
              </>,
            );
          }
        }}
      >
        <img
          src={win7Logo}
          height={30}
          alt="Azure AD"
          style={{ marginRight: "0.6em" }}
        />
        Login
      </Button>
    );
  }
}
