import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { Board, BoardsResponse, NewBoard, Page } from "../../models";
import {
  AddEditBoardModal,
  ConfirmationModal,
  Copier,
  DownloadModal,
  LoginButton,
  PageNumbers,
} from "..";
import { toast } from "react-toastify";
import { HTTP_CLIENT } from "../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { asString, generateCsv, mkConfig } from "export-to-csv";
import { HttpRequestError, toBetterTimeString, toMac } from "../../utils";
import { useNavigate, useSearchParams } from "react-router-dom";

const PATH_PREFIX = "/api/v1/boards";
const DEFAULT_PAGE_SIZE = 10;
const MAX_PAGE_SIZE = 100_000;

interface BoardsProps {
  jwt: string | null;
}

export const Boards: React.FC<BoardsProps> = ({ jwt }) => {
  const [searchParams] = useSearchParams();

  const [data, setData] = useState<Board[]>([]);
  const [pageMeta, setPageMeta] = useState<Page>({
    size: 0,
    number: 0,
    totalElements: 0,
    totalPages: 0,
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [boardToEdit, setBoardToEdit] = useState<Board | undefined>();
  const [fullBoardListBlob, setFullBoardListBlob] = useState<
    Blob | undefined
  >();
  const [showExportModal, setShowExportModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [boardToDelete, setBoardToDelete] = useState<Board | undefined>();

  const pageSize = useMemo(
    (): number => Number(searchParams.get("size") || `${DEFAULT_PAGE_SIZE}`),
    [searchParams],
  );
  const zeroIndexedPageNumber = useMemo((): number => {
    const requestedPage = Number(searchParams.get("page") || "1");
    return Math.min(Math.max(1, requestedPage), pageMeta.totalPages) - 1;
  }, [searchParams, pageMeta]);
  const filterSelector = useMemo((): "mac" | "serial" => {
    const selector = searchParams.get("filterSelector") || "mac";
    if (["mac", "serial"].includes(selector)) {
      return selector as "mac" | "serial";
    } else {
      return "mac";
    }
  }, [searchParams]);
  const macFilter = useMemo(
    (): string => searchParams.get("mac") || "",
    [searchParams],
  );
  const serialFilter = useMemo(
    (): string => searchParams.get("serial") || "",
    [searchParams],
  );

  const navigate = useNavigate();
  const doNavigation = useCallback(
    (newParams: Record<string, string | undefined>): void => {
      const params = new URLSearchParams(window.location.search);
      Object.entries(newParams).forEach(([name, value]) => {
        if (!value) {
          params.delete(name);
        } else {
          params.set(name, value);
        }
      });
      navigate(`${window.location.pathname}?${params.toString()}`, {
        replace: true,
      });
    },
    [navigate],
  );

  const getData = useCallback(
    async (pageSize: number, page: number) => {
      let response: BoardsResponse;
      if ("serial" === filterSelector && serialFilter.length) {
        response = await HTTP_CLIENT.get<BoardsResponse>({
          path: `${PATH_PREFIX}/search/getByModel_IdAndSerialContaining`,
          query: {
            size: pageSize,
            page,
            modelId: 2,
            serial: serialFilter,
          },
          headers: { Authorization: `Bearer ${jwt}` },
        });
      } else if ("mac" === filterSelector && macFilter.length) {
        response = await HTTP_CLIENT.get<BoardsResponse>({
          path: `${PATH_PREFIX}/search/getByModel_IdAndMacContaining`,
          query: {
            size: pageSize,
            page,
            modelId: 2,
            mac: macFilter,
          },
          headers: { Authorization: `Bearer ${jwt}` },
        });
      } else {
        response = await HTTP_CLIENT.get<BoardsResponse>({
          path: `${PATH_PREFIX}/search/getByModel_Id`,
          query: {
            size: pageSize,
            page,
            modelId: 2,
          },
          headers: { Authorization: `Bearer ${jwt}` },
        });
      }
      return response;
    },
    [filterSelector, serialFilter, macFilter, jwt],
  );

  const updateList = useCallback(async () => {
    try {
      const boards = await getData(pageSize, zeroIndexedPageNumber);
      setData(boards._embedded.boards || []);
      setPageMeta(boards.page);
    } catch (e: any) {
      if (e instanceof HttpRequestError) {
        if (e.response.status === 404) {
          setData([]);
        } else {
          toast.error(e.message);
        }
      } else {
        toast.error(e.message);
      }
    }
  }, [getData, zeroIndexedPageNumber, pageSize]);

  const saveBoard = useCallback(
    async (newBoard: NewBoard) => {
      try {
        const boardIsNew = !boardToEdit;
        await HTTP_CLIENT.request(boardIsNew ? "PUT" : "PATCH", {
          path: `${PATH_PREFIX}/${newBoard.mac}`,
          headers: { Authorization: `Bearer ${jwt}` },
          body: newBoard,
        });
        setBoardToEdit(undefined);
        await updateList();
      } catch (e) {
        console.error(e);
        toast.error("Failed to save new board");
      }
    },
    [updateList, boardToEdit, jwt],
  );

  const deleteBoard = useCallback(
    async (board: Board) => {
      try {
        await HTTP_CLIENT.delete({
          path: `${PATH_PREFIX}/${board.mac}`,
          headers: { Authorization: `Bearer ${jwt}` },
        });
        setBoardToDelete(undefined);
        await updateList();
      } catch (e) {
        console.error(e);
        toast.error("Failed to delete board");
      }
    },
    [jwt, updateList],
  );

  const prepareListForExport = useCallback(async () => {
    setShowExportModal(true);

    let results: BoardsResponse;
    try {
      results = await getData(MAX_PAGE_SIZE, 0);
    } catch (e: any) {
      toast.error(
        "Failed to retrieve full board list from TIMS server. See console for details",
      );
      console.error(e);
      setShowExportModal(false);
      return [];
    }

    console.log(`Got some boards: ${JSON.stringify(results._embedded.boards)}`);
    const simplerResults = results._embedded.boards.map((board) => {
      // Prune off some properties that do not export very well to CSV
      const {
        application_versions,
        lifecycle_events,
        board_properties,
        status,
        failure_code,
        last_updated,
        _links,
        ...simpleProps
      } = board;
      const betterBoard: Record<string, any> = {
        ...simpleProps,
        status: status.name,
        failure_code: failure_code ? failure_code.name : undefined,
        // Date only here because Excel doesn't have a formatting type for date/time
        last_updated: new Date(last_updated * 1000).toLocaleDateString(),
      };
      // Flatten out commonly used lifecycle events
      lifecycle_events.forEach((e) => {
        // Date only here because Excel doesn't have a formatting type for date/time
        const date = new Date(e.timestamp * 1000).toLocaleDateString();
        switch (e.event) {
          case 1:
            betterBoard["provisioning_date"] = date;
            break;
          case 2:
            betterBoard["marking_date"] = date;
            break;
        }
      });
      // Flatten out commonly used properties
      board_properties.forEach((p) => {
        switch (p.property) {
          case 1:
            betterBoard["Size"] = p.value;
            break;
          case 2:
            betterBoard["Finish"] = p.value;
            break;
        }
      });
      // Remove "null" strings from the CSV file
      Object.entries(betterBoard).forEach(([k, v]) => {
        if (v === null) betterBoard[k] = undefined;
      });
      console.log(`Got a better board: ${JSON.stringify(betterBoard)}`);
      return betterBoard;
    });
    const csvData = asString(
      generateCsv(
        mkConfig({
          columnHeaders: [
            { key: "device_uid", displayLabel: "Device UID" },
            { key: "mac", displayLabel: "MAC" },
            { key: "serial", displayLabel: "Serial" },
            { key: "comments", displayLabel: "Comments" },
            { key: "probe", displayLabel: "Debug Probe S/N" },
            { key: "self_test_report", displayLabel: "Self-Test Report" },
            {
              key: "end_of_line_test_report",
              displayLabel: "End-of-Line Test Report",
            },
            { key: "status", displayLabel: "Status" },
            { key: "failure_code", displayLabel: "Failure Code" },
            { key: "provisioning_date", displayLabel: "Provisioning Date" },
            { key: "marking_date", displayLabel: "Marking Date" },
            { key: "Finish", displayLabel: "Ring Finish" },
            { key: "Size", displayLabel: "Ring Size" },
            { key: "last_updated", displayLabel: "Last Updated" },
          ],
          quoteStrings: true,
        }),
      )(simplerResults),
    );
    setFullBoardListBlob(new Blob([csvData], { type: "text/csv" }));
  }, [getData]);

  useEffect(() => {
    if (jwt) {
      updateList().catch((e) => toast.error(e));
    }
  }, [updateList, jwt]);

  if (jwt) {
    const now = new Date();
    return (
      <>
        <Row>
          <Col>
            <h1>
              Token Boards
              {jwt && (
                <>
                  <Button
                    title="Add new board"
                    variant="primary"
                    size="sm"
                    onClick={() => setShowAddEditModal(true)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                  <Button
                    title="Export to CSV"
                    variant="primary"
                    size="sm"
                    onClick={prepareListForExport}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </>
              )}
            </h1>
          </Col>
        </Row>

        <Row>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <Form>
              <Row>
                <Col>
                  <Form.Check
                    type="switch"
                    id="filterSelection"
                    label={"Filter selector"}
                    checked={filterSelector === "serial"}
                    onChange={() => {
                      doNavigation({
                        filterSelector:
                          filterSelector === "mac" ? "serial" : "mac",
                        page: "1",
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="mac">
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder={
                        filterSelector === "mac"
                          ? "MAC address"
                          : "Serial number"
                      }
                      value={
                        filterSelector === "mac" ? macFilter : serialFilter
                      }
                      onChange={async (e) => {
                        filterSelector === "mac"
                          ? doNavigation({
                              mac: toMac(e.target.value || ""),
                              page: "1",
                            })
                          : doNavigation({
                              serial: (e.target.value || "").toUpperCase(),
                              page: "1",
                            });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col xs={4} sm={3} md={2} xl={1} className="ms-auto">
            <Form.Group controlId="pageSize">
              <Form.Label column={false}>Page size</Form.Label>
              <Form.Select
                size={"sm"}
                value={pageSize}
                onChange={(e) => {
                  // Figure out what page we need such that the first element of the current page is included in the
                  // new page
                  const elementIndex = pageMeta.number * pageMeta.size;
                  const newPageSize = Number(e.target.value);
                  const newPage = Math.floor(elementIndex / newPageSize) + 1;

                  doNavigation({ size: e.target.value, page: `${newPage}` });
                }}
              >
                {[10, 15, 20, 25, 40, 100, 200].map((size) => (
                  <option key={`page-size-selector-${size}`} value={size}>
                    {size}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Table striped hover>
              <thead>
                <tr>
                  <th>Model</th>
                  <th>Device UID</th>
                  <th>MAC Address</th>
                  <th>Serial #</th>
                  <th>Status</th>
                  <th>Failure Code</th>
                  <th>Debug Probe</th>
                  <th>Last Updated</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {data.map((board) => (
                  <tr key={`board-${board.mac}`}>
                    <td title={board.model.description || undefined}>
                      {board.model.name}
                    </td>

                    <td>{board.device_uid}</td>

                    <td>
                      <code>{board.mac}</code>
                      <Copier id={board.device_uid} text={board.mac} />
                    </td>

                    <td>
                      <code>{board.serial}</code>
                      <Copier id={board.device_uid} text={board.serial} />
                    </td>

                    <td title={board.status.description || undefined}>
                      {board.status.name}
                    </td>

                    <td title={board.failure_code?.description || undefined}>
                      {board.failure_code?.name}
                    </td>

                    <td>
                      <code>{board.probe}</code>
                      <Copier id={board.device_uid} text={board.probe} />
                    </td>

                    <td>{toBetterTimeString(board.last_updated * 1000)}</td>

                    <td>
                      <Button
                        size="sm"
                        onClick={() => {
                          setBoardToEdit(board);
                          setShowAddEditModal(true);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        style={{ marginLeft: "1em" }}
                        size="sm"
                        variant="danger"
                        onClick={() => {
                          setBoardToDelete(board);
                          setShowDeleteConfirmationModal(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row>
          <Col sm={6} md={6} lg={4}>
            {data.length ? (
              <>
                Showing {pageMeta.size * zeroIndexedPageNumber + 1} to{" "}
                {pageMeta.size * zeroIndexedPageNumber + data.length} of{" "}
                {pageMeta.totalElements} entries
              </>
            ) : (
              <>No data found</>
            )}
          </Col>
          <Col sm={12} md={12} lg={8}>
            <PageNumbers
              currentPage={pageMeta.number}
              totalPages={pageMeta.totalPages}
              onClick={(next) => doNavigation({ page: `${next + 1}` })}
            />
          </Col>
        </Row>

        <AddEditBoardModal
          jwt={jwt}
          show={showAddEditModal}
          initialBoard={boardToEdit}
          onClose={() => setShowAddEditModal(false)}
          onSave={async (newBoard) => {
            try {
              await saveBoard(newBoard);
            } catch (e: any) {
              toast.error(`Failed to save board: ${e.message || e}`);
            }
          }}
        />

        <DownloadModal
          displayName="Full Board List CSV"
          fileName={`${now.getFullYear()}-${String(now.getMonth()).padStart(
            2,
            "0",
          )}-${String(now.getDate()).padStart(2, "0")}_token-board-list.csv`}
          content={fullBoardListBlob}
          show={showExportModal}
          onClose={() => setShowExportModal(false)}
        />

        <ConfirmationModal
          text={`delete ${boardToDelete?.mac}`}
          onHide={() => setShowDeleteConfirmationModal(false)}
          show={showDeleteConfirmationModal}
          onConfirm={async () => {
            await deleteBoard(boardToDelete!);
            setShowDeleteConfirmationModal(false);
          }}
          confirmationButtonText="Delete"
        />
      </>
    );
  } else {
    return <LoginButton />;
  }
};
