import React from "react";
import { Button, Modal } from "react-bootstrap";
import { InMemoryDownload } from "..";

interface Props {
  displayName: string;
  fileName: string;
  content?: Blob;

  show: boolean;
  onClose: () => void;
}

export const DownloadModal = ({
  displayName,
  fileName,
  content,
  show,
  onClose,
}: Props) => {
  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton>Export Data</Modal.Header>

      <Modal.Body>
        <InMemoryDownload
          displayName={displayName}
          fileName={fileName}
          content={content}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
