import React from "react";
import { Col, Form } from "react-bootstrap";

interface TextFieldProps {
  title: string;
  placeholder?: string;
  required?: boolean;
  getValue: () => string;
  onChange: ((value: string) => void) | ((value: string) => Promise<void>);
}

export const TextField = ({
  title,
  placeholder,
  required = true,
  getValue,
  onChange,
}: TextFieldProps) => {
  return (
    <Col xs={12} md={6}>
      <Form.Group controlId={title}>
        <Form.Label>{title}</Form.Label>

        <Form.Control
          type="text"
          placeholder={placeholder}
          required={required}
          value={getValue()}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </Form.Group>
    </Col>
  );
};
